@font-face {
  font-family: 'Helvethaica_Regular';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-55Regular.woff2') format('woff2'),
      url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-55Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvethaica_Medium';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-65Med.woff2') format('woff2'),
      url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-65Med.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvethaica_Bold';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-75Bd.woff2') format('woff2'),
      url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-75Bd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* 
@font-face {
  font-family: 'Prompt';
  src: url("https://fonts.googleapis.com/css?family=Prompt:400,500,600&display=swap");
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Helvethaica_Regular';  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Helvethaica_Regular';
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}


.swal2-html-container{
  font-size: 24px !important;
}

.swal2-confirm{
  font-size: 20px !important;
}