.form-check-input:checked {
    background-color: #3CBE8C;
    /* border-color: #3CBE8C; */
}

.p-password-input {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .main-icon img {
        width: 300px;
        margin-top: 30px;
    }
}