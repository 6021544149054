:root {
	--button: #28825a;
	--green1: #28825a;
	--green2: #32aa78;
	--green3: #3cbe8c;
	--green4: #50d2a0;
	--text1: #756215;
	--text2: #5e8093;
}

.text-color-1 {
	color: var(--green1) !important;
}
.text-color-2 {
	color: var(--green2) !important;
}
.text-color-3 {
	color: var(--green3) !important;
}
.text-color-4 {
	color: var(--green4) !important;
}
.text-color-5 {
	color: var(--text1) !important;
}
.text-color-6 {
	color: var(--text2) !important;
}

.bg-color-1 {
	background-color: var(--green1);
}
.bg-color-2 {
	background-color: var(--green2);
}
.bg-color-3 {
	background-color: var(--green3);
}
.bg-color-4 {
	background-color: var(--green4);
}

.bg-image {
	background: url(../../../../lib/assets/images/main_bg.png);
}

.header {
	background: url(../../../../lib/assets/images/main_bg.png);
	border-bottom: 2px solid #4d8c70;
	font-weight: 600;
}

.header-second {
	border-bottom: 2px solid #4d8c70;
}

.logo {
	font-size: 2em;
	color: #fff;
	user-select: none;
}

.navbar-text {
	font-size: 0.7em;
}

.navigation {
	display: flex;
	flex-direction: row;
}

.navigation a,
.navigation div {
	position: relative;
	font-size: 0.8em;
	color: var(--menu);
	/* color: #28825A; */
	text-decoration: none;
	font-weight: 500;
	margin-left: 5px;
	text-align: center;
}

.navigation a::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -6px;
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 5px;
	transform-origin: right;
	transform: scaleX(0);
	transition: transform 0.5s;
}

.p-inputtext {
	padding: 5px;
}

.p-dialog .p-dialog-content {
	padding: 0%;
}

.p-dialog-header {
	padding: 0%;
}

.p-dialog-title {
	font-family: 'Prompt', sans-serif;
	text-align: center;
	margin-left: 40px;
}

.custom-offcanvas {
	max-width: 100px !important;
}

.menu {
	display: flex;
	justify-content: space-between;
}

.links {
	color: var(--menu);
	text-decoration: none;
}

.header-profile-group {
	width: 100%;
}

.header-profile-group .image-icon {
	width: 60px;
	height: 60px;
}

.header-profile-text-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.image-icon {
	height: 50px;
	margin-left: 1em;
	margin-right: 1em;
}

.navbar-link-custom {
	font-size: 1.4em;
	margin: 0 20px 0;
}

.navbar-text {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.p-dialog-footer  {
	padding: 0%;
}
.button-group {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}
.p-button-upload {
	padding: 5px 15px;
	color: white;
	margin-bottom: 5px;
	background-color: var(--green2);
	border-radius: 5px;
}

.p-button-secondary {
	padding: 5px 15px;
	color: white;
	margin-bottom: 5px;
	background-color: red;
	border-color: red;
	border-radius: 5px;
}

.profile-image-select {
	text-align: center ;
} 

.profile-image-select .p-fileupload.p-fileupload-advanced.p-component {
	margin-left: 17px;
	width: 24rem;
}