@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
	--green1: #28825a;
	--green2: #32aa78;
	--green3: #3cbe8c;
	--green4: #50d2a0;
	--green5: #bdddcb;
	--green6: #cee3d7;
	--menu: #000;
	--text: #000;
	--text1: #756215;
	--text2: #5e8093;
	--button: var(--green1);
}

.text-color-1 {
	color: var(--green1) !important;
}
.text-color-2 {
	color: var(--green2) !important;
}
.text-color-3 {
	color: var(--green3) !important;
}
.text-color-4 {
	color: var(--green4) !important;
}
.text-color-5 {
	color: var(--text1) !important;
}
.text-color-6 {
	color: var(--text2) !important;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Prompt', sans-serif;
}

body {
	font-family: 'Helvethaica_Regular';
	/* font-family: 'Bai Jamjuree', sans-serif; */
	color: #282828;
	font-size: 20px;
	background: url('assets/images/main_bg.png') no-repeat;
	background-color: #cee3d7;
	background-size: cover;
	min-height: 100vh;
}

.container-size {
	min-height: calc(100vh - 164px);
}

footer {
	background: url('assets/images/footer.png') no-repeat;
	background-size: cover;
	padding: 35px;
	margin-top: auto;
}

.header-main {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 20px 100px;
	background: transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 99;
}

.header-main img {
	height: 80px;
	/* border: 5px solid #000; */
	position: absolute;
}

.logo-main {
	font-size: 2em;
	color: #fff;
	user-select: none;
}

.navigation-main a {
	position: relative;
	font-size: 0.8em;
	color: var(--menu);
	/* color: #28825A; */
	text-decoration: none;
	font-weight: 500;
	margin-left: 40px;
}

.navigation-main a::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -6px;
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 5px;
	transform-origin: right;
	transform: scaleX(0);
	transition: transform 0.5s;
}

.navigation-main a:hover::after {
	transform-origin: left;
	transform: scaleX(1);
}

.wrapper {
	position: relative;
	width: 400px;
	height: 490px;
	background: transparent;
	border: 2px solid rgba(255, 255, 255, 0.5);
	border-radius: 20px;
	backdrop-filter: blur(3px);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
	display: flex;
	right: 0;
	overflow: hidden;
	transition: transform 0.5s ease, height 0.2s ease;
}

.wrapper.active-popup {
	transform: scale(1);
}

.wrapper .form-box {
	width: 100%;
	padding: 40px;
}

.wrapper.active .form-box {
	padding: 20px 40px;
}

.wrapper .form-box.login {
	transition: transform 0.18s ease;
	transform: translateX(0);
}

.wrapper.active .form-box.login {
	transition: none;
	transform: translateX(-400px);
}

.wrapper .form-box.register {
	position: absolute; /*need this*/
	transition: none;
	transform: translateX(400px);
}

.wrapper.active .form-box.register {
	transition: transform 0.18s ease;
	transform: translateX(0);
}

.wrapper .icon-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 45px;
	height: 45px;
	background: #162938;
	font-size: 2em;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom-left-radius: 20px;
	cursor: pointer;
	z-index: 1;
}

.form-box h2 {
	font-size: 1.4em;
	color: #162938;
	text-align: center;
}

.input-box {
	position: relative;
	width: 100%;
	height: 50px;
	border-bottom: 2px solid #162938;
	margin: 25px 0;
}

.wrapper.active .input-box {
	margin: 15px 0;
}

.wrapper.active .input-box.top {
	margin: 8px 0;
}

.input-box label {
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	font-size: 0.7em;
	color: #162938;
	font-weight: 500;
	pointer-events: none;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
	top: -5px;
}

.input-box input {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	outline: none;
	font-size: 0.8em;
	color: #162938;
	font-weight: 600;
	padding: 0 35px 0 5px;
}

.input-box .icon {
	position: absolute;
	right: 8px;
	font-size: 1.2 em;
	color: #162938;
	line-height: 57px;
}

.remember-forgot {
	font-size: 1em;
	color: #162938;
	font-weight: 500;
	margin: -5px 0 15px;
}

.remember-forgot label input {
	accent-color: #162938;
	margin-right: 3px;
}

.remember-forgot a {
	color: #162938;
	text-decoration: none;
}

.remember-forgot a:hover {
	text-decoration: underline;
}

.btn-login {
	width: 100%;
	height: 45px;
	background: #162938;
	border: none;
	outline: none;
	border-radius: 6px;
	cursor: pointer;
	font-size: 0.7em;
	color: #fff;
	font-weight: 500;
}

.btn-register {
	margin-top: 5px;
}

.login-register {
	font-size: 0.7em;
	color: #162938;
	text-align: center;
	font-weight: 500;
	margin: 5px 0 0px;
}

.login-register p a {
	color: #162938;
	text-decoration: none;
	font-weight: 600;
}

.login-register p a:hover {
	text-decoration: underline;
}

.intro_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	height: 83vh;
	width: 100%;
	background-size: cover;
	padding-top: 12vh;
}

.text-container {
	/* width: 520px; */
	font-size: 0.8em;
	padding-left: 20px;
}

.text-container h2 {
	font-size: 1.3em;
}

.swal2-styled {
	font-size: 1.2em !important;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-gap: 10px;
	width: 80%;
}

.grid-item {
	padding: 10px;
	text-align: center;
}

.grid-item img {
	height: 140px;
	padding: 10px;
	border: 3px solid orange;
	border-radius: 30px 0px 30px 30px;
}

.grid-item img:hover {
	box-shadow: 2px 2px 5px orange;
}

.google-login-dashed-line {
	display: flex;
	align-items: center;
}

.google-login-dashes {
	flex: 1;
	border-top: 1px solid black;
}

.google-login-text {
	padding: 0 10px;
	font-size: 0.7em;
}

.google-login-button {
	display: flex;
	justify-content: center;
}

.btn-google-login {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: transparent;
	border-color: gray;
	color: grey;
}

.btn-google-login:hover {
	background-color: transparent;
}

.btn-user {
	padding: 0.5rem;
	border-radius: 10px;
	margin: 1rem;
}

.p-inputtext {
	padding: 5px;
}

.p-button.p-button-icon-only.p-button-rounded {
	margin-bottom: -2px !important;
}

.p-dialog-title {
	text-align: center;
	margin-left: 40px;
}

.button-group {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}

.p-button {
	border: none;
	box-shadow: none;
}

.p-button-upload {
	padding: 5px 15px;
	color: white;
	margin-bottom: 5px;
	background-color: var(--green2);
	border-radius: 5px;
}

.p-button-secondary {
	padding: 5px 15px;
	color: white;
	margin-bottom: 5px;
	background-color: red;
	border-color: red;
	border-radius: 5px;
}

.p-button-text.add-edu-cancel {
	padding: 5px 15px;
	color: white;
	margin-bottom: 5px;
	background-color: red;
	border-color: red;
	border-radius: 5px;
}

.p-field {
	margin: 5px 10px;
}

.p-button.p-fileupload-choose {
	background-color: var(--green2);
	padding: 5px 10px;
}

.bg-image {
	background: url(../src/assets/images/main_bg.png);
}

svg.p-icon.p-dropdown-filter-icon {
	top: 30% !important;
}

.p-input-icon-right > i {
    position: absolute;
    top: 20% ;
    margin-top: -1px ;
}

.spinner-loading {
	justify-content: center;
	text-align: center;
	padding: 300px 0px;
}

.p-dialog .p-dialog-header .p-dialog-title{
	font-weight: 500;
    font-size: 1.35rem;
}

@media only screen and (max-width: 1200px) {
	.text-b {
		max-width: 300px;
		font-size: 1.5rem;
	}
}
