:root {
	--button: #28825a;
	--green1: #28825a;
	--green2: #32aa78;
	--green3: #3cbe8c;
	--green4: #50d2a0;
  --green5: #bdddcb;
	--green6: #cee3d7;
	--text1: #756215;
	--text2: #5E8093;
	
}

  .header-content {
    max-width: 800px; 
    margin: 0 auto; 
  }

  .header-table {
    background: url(../../../../lib/assets/images/main_bg.png);
    font-weight: 600;
    padding: 0;
  }

  .setting-content {
    /* border: 2px solid black; */
    width: 80%;
  }

 .setting-text {
   margin-bottom: 10px;
   margin-top: 10px;
 }

 .setting-text-item {
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-text {
  margin-bottom: 20px;
  font-weight: 100;
}

.switch-container {
  display: flex;
  align-items: center;
}

.switch-label {
  margin-right: 10px; 
  opacity: 0.5;
}

.p-input-icon-left > i {
  position: absolute;
  top:50%;
  margin-top: -0.5rem;
 } 

.p-input-icon-left > .p-inputtext {
  padding-left: 2.6rem;
}

.p-input-icon-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.p-datatable .p-column-header-content {
  padding: 0.4rem 0.2rem;
}

.p-datatable .p-datatable-thead > th {
  padding: 5px 5px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-tag-value {
  padding: 0.5rem;
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0.5rem;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  margin-bottom: -0.1rem;
  height: 2.5rem;
  width: 2.5rem;
}

.p-datatable-striped .p-datatable-tbody tr:nth-child(even) {
  background-color: white;
}

.p-datatable-striped .p-datatable-tbody tr:nth-child(odd) {
  background-color: #EDFFF4; 
}

.p-dropdown .p-dropdown-clear-icon {
  position: absolute;
  top:50%;
  margin-top: -0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: black;
  /* background-color: var(--green2); */

  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--green5);
  border-color: var(--green5);
 
  color: black;
}
.p-dropdown-items{
  margin: 0px 0px;
  padding: 0.5rem;
}

.p-datatable .p-column-header-content{
  background: url(../../../../../public/shared/blh_bg2.png);

  text-align: center ;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success{
 background-color: var(--green2);  
}

.p-column-title{
  text-align: center;
}

.btn-user.btn-toggle{
  border: 1px solid gray;
  background-color: white;
  color: gray;
  font-weight: bold;
}

.p-datatable .p-sortable-column :hover{
  background-color: gainsboro;
  
}

.p-datatable th{
  background: url(../../../../../public/shared/blh_bg2.png);
  /* border-left: 1px groove black;
  border-right: 1px groove black;
  border-top: 1px groove black; */
  text-align: center ;
}

.pi-search:before{
  padding: 5px;
  margin-right: -5px;
}

.confirm-dialog {
  justify-content: center;
  align-items: center;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
	margin: 20px;
}

.p-password-input {
  width: 100%;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--green1) !important;
}

/* .p-column-filter-row{
  border-bottom:1px groove black; ;
} */