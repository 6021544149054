
.custom-confirm-button {
    background-color: var(--button) !important; 
    color: #fff !important;
    border: none !important; 
    font-size: 16px !important; 
    cursor: pointer !important; 
    width: 550px !important;
}

.custom-confirm-button:hover {
    background-color: var(--button) !important;
}

.p-field.file-upload-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 40px;
}

.p-fileupload-row {
    justify-content: center;
}

#csvFile.p-fileupload.p-fileupload-advanced.p-component{
    width: 25rem;
}